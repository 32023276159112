import React from "react";
import "./projectComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image1 from "./Aerolink_images/image1.png";
import image2 from "./Aerolink_images/image2.png";
import image3 from "./Aerolink_images/image3.png";
import image4 from "./Aerolink_images/image4.png";
import image5 from "./Aerolink_images/image5.png";
import image6 from "./Aerolink_images/image6.png";
import image7 from "./Aerolink_images/image7.png";
import image8 from "./Aerolink_images/image8.png";
import image9 from "./Aerolink_images/image9.png";
import image10 from "./Aerolink_images/image10.png";
import image11 from "./Aerolink_images/image11.png";
import image12 from "./Aerolink_images/image12.png";
import image13 from "./Aerolink_images/image13.png";
import image14 from "./Aerolink_images/image14.png";
import image15 from "./Aerolink_images/image15.png";
import image16 from "./Aerolink_images/image16.png";
import image17 from "./Aerolink_images/image17.png";
import image18 from "./Aerolink_images/image18.png";
import image19 from "./Aerolink_images/1.png";
import image20 from "./Aerolink_images/2.png";
import image21 from "./Aerolink_images/3.png";
import image22 from "./Aerolink_images/4.png";
import image23 from "./Aerolink_images/5.png";
import image24 from "./Aerolink_images/6.png";



const AeroLink = () => {
    const images = [image1, image2, image3,image4, image5,image6 ,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,image17,image18,image19,image20,image21,image22,image23,image24];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">AeroLink</h2>
        <span className="section__subtitle">Data Science/BI Project</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
            <div className="skills__container1 container grid">
        <div className="skills__content">
        <h3 className="skills__title">Technologies Used</h3>

        <div className="skills__box">

        <div className="skills__group">

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">Python</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">ML/DL</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">Flask</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">SSIS</h3>
                <span className="skills__level">Data Eng</span>
              </div>
            </div>

            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">PowerBI</h3>
                <span className="skills__level">Development</span>
              </div>
            </div>
          
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Git</h3>
              <span className="skills__level">Cooperation</span>
            </div>
          </div>
        </div>

        </div>
    </div>
            </div>
            <div className="text__container">
    <h3 className="title">Description:</h3>
    <br />
    <p className='redRadio__text'>-Led a comprehensive data preparation project leveraging Python, SSIS, and SSMS, ensuring data quality and integrity for analysis.</p>
    <br />
    <p className='redRadio__text'>-Applied unsupervised learning techniques to uncover hidden patterns and insights within the dataset, driving informed decision-making.</p>
    <br />
    <p className='redRadio__text'>-Visualized key findings through interactive Power BI dashboards, facilitating clear communication of complex data insights to stakeholders.</p>
    <br />
    <p className='redRadio__text'>-Utilized supervised learning algorithms to develop predictive models, enabling accurate forecasting and strategic planning.</p>
    <br />
    <p className='redRadio__text'>-Designed and deployed a Flask web application, providing seamless access to data-driven insights and predictive analytics.</p>
</div>

        </div>
    </section>
    );
};

export default AeroLink;
