import React from "react";
import "./projectComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image2 from "./RedRadio_images/2.png";
import image3 from "./RedRadio_images/3.png" ;
import image4 from "./RedRadio_images/4.png" ;
import image5 from "./RedRadio_images/6.webp";

const RedRadio = () => {
    const images = [image5,image2, image3, image4];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">RedRadio</h2>
        <span className="section__subtitle">Management Desktop Application</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
            <div className="skills__container1 container grid">
        <div className="skills__content">
        <h3 className="skills__title">Technologies Used</h3>

        <div className="skills__box">

        <div className="skills__group">

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">C++</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">HTML/CSS</h3>
                <span className="skills__level">Design</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">SQL</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Qt</h3>
                <span className="skills__level">Frame Work</span>
              </div>
            </div>

            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Figma</h3>
                <span className="skills__level">Design</span>
              </div>
            </div>
          
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Git</h3>
              <span className="skills__level">Cooperation</span>
            </div>
          </div>
        </div>

        </div>
    </div>
            </div>

            <div className="text__container">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>Worked in a team of 5 students to develop a desktop application using the Qt framework and C++. Collaborated on the implementation of the application's features and user interface, utilizing C++ to improve performance and functionality.</p>
                <br />
                <p className='redRadio__text'>The app was made to help a radio manage their activities such as employees schedule, events, emissions, sponsors, guests invitations, equipment use, ads.</p>
            </div>
        </div>
    </section>
    );
};

export default RedRadio;
