import React from 'react';
import './achievements.css';

import image1 from "./images/thumb2.png";
import image2 from "./images/thumb.png";
import image3 from "./images/thumb3.png";

import { Link } from 'react-router-dom';

const achievements = [
  {
    id: 1,
    title: 'HACKATHON 6.0',
    description: 'by ATLAS TBS',
    image: image1,
    demoLink: "/HACKATHON6.0",
   
  },
  {
    id: 2,
    title: 'Sustainable Minds²',
    description: 'by SSDteam & Esprit',
    image: image2,
    demoLink:"/HackMinds",

  },
  {
    id: 3,
    title: 'GreenAct',
    description: 'by Esprit',
    image: image3,
    demoLink:"/HackEsprit",

  }
  
  // Add more project objects as needed
];

const Achievements = () => {
  return (
    <section className="portfolio section" id='achievements'>
    <div className="project-list" >
      <h2 className="section__title" >My Achievements</h2>
      <span className="section__subtitle">Have a look!</span>
      <div className="project-list__container">
        {achievements.map((achievements) => (
          <div className="project" key={achievements.id}>
            <div className="project__image">
              <img src={achievements.image} alt={achievements.title} />
            </div>
            <div className="project__content">
              <h3 className="project__title">{achievements.title}</h3>
              <p className="project__description">{achievements.description}</p>
              <div className="project__links">
                <Link to={achievements.demoLink} className='links__projects'>Details</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
  );
};

export default Achievements;
