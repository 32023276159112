import React from 'react';
import './projects.css';
import image1 from "./images/redRadio.jpg";
import image2 from "./images/logo2.png";
import image3 from "./images/CD_COVER_2.png";
import image4 from "./images/logo.png"
import image5 from "./images/agrosmart.jpeg"
import { Link } from 'react-router-dom';
const projects = [
  {
    id: 1,
    title: 'Red-Radio',
    description: 'Desktop Application',
    image: image1,
    demoLink: "/RedRadio",
    githubLink: 'https://github.com/OuhabYouceff/Boutelliss-SDL-2DGame',
  },
  {
    id: 2,
    title: 'Calmini',
    description: 'Web Application',
    image: image2,
    demoLink:"/Calmini",
    githubLink: 'https://github.com/OuhabYouceff/RedRadio-Qt-App',
  },
  {
    id: 3,
    title: 'Boutellis',
    description: '2D Game',
    image: image3,
    demoLink: "/Boutellis",
    githubLink: 'https://github.com/OuhabYouceff/Calmini-PHP-App',
  },
  {
    id: 4,
    title: 'AeroLink',
    description: 'Data Science/BI Project',
    image: image4,
    demoLink: "/AeroLink",
    githubLink: '',
  },
  {
    id: 5,
    title: 'AgroSmart',
    description: 'Currently In Development',
    image: image5,
    demoLink: "/",
    githubLink: '',
  }
  
  // Add more project objects as needed
];

const ProjectList = () => {
  return (
    <section className="portfolio section" id='portfolio'>
    <div className="project-list" >
      <h2 className="section__title" >Previous Projects</h2>
      <span className="section__subtitle">Have a look!</span>
      <div className="project-list__container ">
        {projects.map((project) => (
          <div className="project" key={project.id}>
            <div className="project__image">
              <img src={project.image} alt={project.title} />
            </div>
            <div className="project__content">
              <h3 className="project__title">{project.title}</h3>
              <p className="project__description">{project.description}</p>
              <div className="project__links">
                <Link to={project.demoLink} className='links__projects'>Details</Link>
                <a href={project.githubLink} className='links__projects' target="_blank" rel="noopener noreferrer">GitHub</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
  );
};

export default ProjectList;
