import React from 'react';
import "./certificates.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';
import image1 from "./images/ML.png";
import image2 from "./images/WEB1.png";
import image3 from "./images/WEB2.png";
import image4 from "./images/WEB_COURSERA.png";
import image5 from "./images/nvidia.png";

const Certificates = () => {
  const images = [image1, image2, image3, image4,image5];
  
  return (
    <section className="certificates section" id='certificate'>
      <h2 className="section__title">Certificates</h2>
      <div className='slide-container'>
        <Fade>
          {images.map((image, index) => (
            <div className='divImage'>
                 <img src={image} alt="Slider"/>
            </div>
          ))}
        </Fade>
      </div>
    </section>
  );
};

export default Certificates;
