import React from 'react';

const Social = () =>{

    return(
        <div className="home__social">
            <a href="https://www.instagram.com/youcef_ouhab/?hl=fr" className='home__social-icon' target='_blanc'>
            <i class="uil uil-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/youcef-ouhab/" className='home__social-icon' target='_blanc'>
            <i class="uil uil-linkedin"></i>
            </a>
            <a href="https://github.com/OuhabYouceff" className='home__social-icon' target='_blanc'>
            <i class="uil uil-github"></i>
            </a>
        </div>
    )
}


export default Social