import React from "react";
import "./achievementComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image2 from "./images/image1/2.jpeg";
import image1 from "./images/image1/1.jpeg";
import image3 from "./images/image1/3.jpeg" ;
import image4 from "./images/image1/4.jpeg" ;
import image5 from "./images/image1/5.jpeg";
import image6 from "./images/image1/6.jpeg";

const HackMinds = () => {
    const images = [image1,image2,image3, image4, image5,image6];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">Sustainable Minds²</h2>
        <span className="section__subtitle">Third Place winner🥉</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
           

            <div className="text__container1">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>We are proud to have secured the 3rd place 🥉 in the "Sustainable Minds²" Hackathon, which took place from March 1st to 3rd, 2024. Regarding our project in the hackathon, we focused on addressing SDG 6 (clean water and sanitation). Our solution, "WaterMe", aims to empower both individuals and industries to monitor their water consumption effectively. By utilizing smart water meters, WaterMe provides real-time data, detects potential leaks, and offers predictive models.Our user-friendly website features intuitive dashboards for enhanced visibility of water usage and incorporates challenges to encourage users to adopt more sustainable water practices.I'm genuinely grateful for this enriching experience and eagerly look forward to embracing similar challenges in the future. Bring on what lies ahead! 🚀</p>

            </div>
        </div>
    </section>
    );
};

export default HackMinds;
