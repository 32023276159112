import React from "react";
import "./projectComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image1 from "./Boutellis_images/menu1.png";
import image2 from "./Boutellis_images/settings.png";
import image3 from "./Boutellis_images/game1.png";
import image4 from "./Boutellis_images/Screenshot from 2023-06-20 17-30-56.png";
import image5 from "./Boutellis_images/game2.png";
import image6 from "./Boutellis_images/game3.png";
import image7 from "./Boutellis_images/Screenshot from 2023-06-20 17-31-14.png";

const Boutellis = () => {
    const images = [image1, image2, image3,image4, image5,image6 ,image7];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">Boutellis</h2>
        <span className="section__subtitle">2D Game</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
            <div className="skills__container1 container grid">
        <div className="skills__content">
        <h3 className="skills__title">Technologies Used</h3>

        <div className="skills__box">

        <div className="skills__group">

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">C</h3>
                <span className="skills__level">Development</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">Photoshop</h3>
                <span className="skills__level">Design</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">Ae</h3>
                <span className="skills__level">Design</span>
            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">SDL</h3>
                <span className="skills__level">Development</span>
              </div>
            </div>

            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Illustrator</h3>
                <span className="skills__level">Design</span>
              </div>
            </div>
          
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Git</h3>
              <span className="skills__level">Cooperation</span>
            </div>
          </div>
        </div>

        </div>
    </div>
            </div>

            <div className="text__container">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>Collaborated with five students to develop a captivating game utilizing the power of C language and the SDL 1.2 library.</p>
                <br />
                <p className='redRadio__text'>Together, we combined our skills and creativity to design an immersive gaming experience that seamlessly blended advanced programming concepts with captivating visuals and smooth gameplay.</p>
            </div>
        </div>
    </section>
    );
};

export default Boutellis;
