import React from "react";
import "./skills.css";

const Software = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Software development</h3>

      <div className="skills__box">
        <div className="skills__group">

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">C</h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">C++</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">JAVA</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>
          
        </div>

        <div className="skills__group">
        <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">SDL</h3>
                <span className="skills__level">Advanced</span>
              </div>
            </div>
            
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Qt</h3>
                <span className="skills__level">Advanced</span>
              </div>
            </div>

            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Linux</h3>
                <span className="skills__level">Advanced</span>
              </div>
            </div>

            

            

        </div>
      </div>
    </div>

  );
};

export default Software;
