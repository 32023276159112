import React from "react";
import "./Calmini.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image5 from "./web_images/aceuille.PNG";
// import image6 from "./web_images/admin-user.PNG";
// import image8 from "./web_images/adminuser2.PNG";
// import image7 from "./web_images/adminuser3.PNG";
import image2 from "./web_images/login.PNG";
import image1 from "./web_images/main.PNG";
import image4 from "./web_images/mainadmin.PNG";
import image3 from "./web_images/signup.PNG";


const Calmini = () => {
    const images = [image1, image2, image3,image4, image5];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">Calmini</h2>
        <span className="section__subtitle">Web Managment Application</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage2">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
            <div className="skills__container1 container grid">
        <div className="skills__content">
        <h3 className="skills__title">Technologies Used</h3>

        <div className="skills__box">

        <div className="skills__group">

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">PHP</h3>
                <span className="skills__level">Devloppment</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">HTML/CSS</h3>
                <span className="skills__level">Design</span>
            </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
                <h3 className="skills__name">Ajax</h3>
                <span className="skills__level">Devloppment</span>
            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">MySQL</h3>
                <span className="skills__level">Devloppment</span>
              </div>
            </div>

            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">Figma</h3>
                <span className="skills__level">Design</span>
              </div>
            </div>
          
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Git</h3>
              <span className="skills__level">Cooperation</span>
            </div>
          </div>
        </div>

        </div>
    </div>
            </div>

            <div className="text__container">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>Worked with a team of 6 students to build a web application utilizing PHP, HTML, CSS, and JavaScript. Implemented the AJAX technique to enable real-time data transfer and enhance user interactivity.</p>
                <br />
                <p className='redRadio__text'>The App was designed to help people with Mental health issues find solutions such as discussing their problems with Professionals and other people suffering with same problems (online - video-meet & anonymous blog comments) and participating in events.</p>
            </div>
        </div>
    </section>
    );
};

export default Calmini;
