import React from "react";
import "./skills.css";

const DataEn = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Data Engineering</h3>

      <div className="skills__box">
        <div className="skills__group">

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Python</h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">PowerBI</h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Machine Learning</h3>
              <span className="skills__level">Advanced</span>
            </div>
          </div>

          
        </div>

        <div className="skills__group">
        <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">SSIS</h3>
                <span className="skills__level">Advanced</span>
              </div>
            </div>
            
            <div className="skills__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">SSMS</h3>
                <span className="skills__level">Intermediate</span>
              </div>
            </div>

            <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Deep Learning</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>

            

        </div>
      </div>
    </div>

  );
};

export default DataEn;
