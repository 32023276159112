import React from 'react';
import "./skills.css"
const Backend = () =>{

    return(
        <div className="skills__content">
        <h3 className="skills__title">Backend development</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">PHP</h3>
                    <span className="skills__level">Advanced</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">Oracle</h3>
                    <span className="skills__level">Advanced</span>
                </div>
                </div>


                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">NodeJs</h3>
                    <span className="skills__level">Intermidate</span>
                </div>
                </div>
                
            </div>

            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">MySQL</h3>
                    <span className="skills__level">Advanced</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">Ajax</h3>
                    <span className="skills__level">Intermidate</span>
                </div>
                </div>

                <div className="skills__data">
                <i class='bx bx-badge-check' ></i>
                <div>
                    <h3 className="skills__name">Flask</h3>
                    <span className="skills__level">Intermidate</span>
                </div>
                </div>

            </div>

            
        </div>
    </div>
    )
}


export default Backend