import React from 'react';
import "./skills.css"
import Frontend from './frontend';
import Backend from './backend';
import Software from './Software';
import DataEn from './dataEn';
const Skills = () =>{

    return(
        <section className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">My technichal level</span>

            <div className="skills__container container grid">
                <Frontend/>
                
                <Backend/>

                <Software/>

                <DataEn/>
            </div>
        </section>
    )
}


export default Skills