import React, {useState} from 'react';
import "./qualification.css"

const Qualification = () =>{

    const [toggleState, setToggleState]= useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }
    return(
        <section className="qualification section" id='qualification'>
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My Journey</span>



            <div className="qualification__container container"> 
                <div className="qualification__tabs">
                    <div onClick={ () => toggleTab(1)} className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
                        <i className="uil uil-graduation-cap qualification__icon"></i> Education
                    </div>

                    <div onClick={ () => toggleTab(2)} className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                    <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Software Engineer </h3>
                                <span className="qualification__subtitle">Tunis - ESPRIT-HONORIS</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>2021-Present
                                </div>
                                
                            </div>
                            
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Nvidia - Fundamentals of Deep Learning</h3>
                                <span className="qualification__subtitle"></span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>February 2024.
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        

                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Coursera - Machine Learning</h3>
                                <span className="qualification__subtitle">With Python</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>April 2023-May 2023.
                                </div>
                            </div>
                            
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Coursera - Web Development</h3>
                                <span className="qualification__subtitle">Johns Hopkins University</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>January 2023-February 2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">

                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">GomyCode - Introduction to ReactJS</h3>
                                <span className="qualification__subtitle"></span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>September 2022-February 2023
                                </div>
                            </div>
                            
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">GomyCode - Web Development</h3>
                                <span className="qualification__subtitle"></span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>September 2022-February 2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                                
                            </div>
                            
                        </div>
                    </div>

                    
                    <div  className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                    
                    <div className="qualification__data">
                    
                            <div>
                                <h3 className="qualification__title">Agriculture AI Project</h3>
                                <span className="qualification__subtitle">DL/ML - Transfer Learning - Flutter/Flask</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>January 2024-May 2024
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Data Science/BI Project</h3>
                                <span className="qualification__subtitle">Python - Flask - ML/DL</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>September 2023-December 2023
                                </div>
                            </div>
                            
                        </div>
                    <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Web Application</h3>
                                <span className="qualification__subtitle">PHP - AJAX - MySqL</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>January 2023-May 2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Desktop Application</h3>
                                <span className="qualification__subtitle">C++ - QT - Oracle</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>September 2022-December 2022
                                </div>
                            </div>
                            
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Summer Intern</h3>
                                <span className="qualification__subtitle">Kh-Consulting - Tunis</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>August 2022-September 2022
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">2D Game</h3>
                                <span className="qualification__subtitle">C - SDL 1.2</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>January 2022-May 2022
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Qualification