import React from "react";
import "./achievementComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image2 from "./images/image/image4.jpeg";
import image1 from "./images/image/image1.jpeg";
import image3 from "./images/image/image3.jpeg" ;


const HackEsprit = () => {
    const images = [image1,image2,image3];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">GreenAct Esprit</h2>
        <span className="section__subtitle">Ideation phase winner🥇</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
           

            <div className="text__container1">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>Our team TechnoTitans won the ideation phase in the Green Act Hackathon! With our AI-powered soil management system, we clinched first place in the ideation phase and are now gearing up to implement our project on our university campus. Our solution not only won the hearts of the judges but also made a significant impact on Sustainable Development Goals 13, 8, and 3. Stay tuned for more updates on our journey towards a greener future!" 🌱🏆</p>

            </div>
        </div>
    </section>
    );
};

export default HackEsprit;
