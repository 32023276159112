import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Skills from './components/skills/skills';
//import Services from './components/services/services';
import Qualification from './components/qualifications/qualification';
import Certificates from './components/certificates/certificates';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import ScrollUp from './components/scrollup/scrollup';
import ProjectList from './components/projects/projects';
import Calmini from './components/projects/calmini'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import RedRadio from './components/projects/redRadio';
import Boutellis from './components/projects/boutellis';
import AeroLink from './components/projects/Aerolink';
import AgroSmart from './components/projects/AIproject';
import ScrollToTop from './components/projects/scrollToTop/scrollToTop';
import Achievements from './components/achievements/achievements';
import HackAtlas from './components/achievements/hackAtlas';
import HackMinds from './components/achievements/hackMinds';
import HackEsprit from './components/achievements/hackEsprit';
function App() {
  return (
    <>
      <Header/>
    <BrowserRouter>
    
    <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={

          <>
         
          <Home/>
          <About/>
          <Skills/>
          <Qualification/>
          <ProjectList/>
          <Achievements/>
          <Certificates/>
          <Contact/>
         
          </>

          }/>

          <Route path="/RedRadio" element={<RedRadio />} />
          <Route path="/Calmini" element={<Calmini />} />
          <Route path="/Boutellis" element={<Boutellis />} />
          <Route path="/AeroLink" element={<AeroLink />} />
          <Route path="/HACKATHON6.0" element={<HackAtlas />} />
          <Route path="/HackMinds" element={<HackMinds />} />
          <Route path="/HackEsprit" element={<HackEsprit />} />

      </Routes>
    </BrowserRouter>

    <Footer/>
    <ScrollUp/>
    
    </>
  );
}

export default App;
