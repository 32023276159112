import React from "react";
import "./achievementComponent.css";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';

import image2 from "./images/2.jpeg";
import image1 from "./images/1.jpeg";
import image3 from "./images/3.jpeg" ;
import image4 from "./images/4.jpeg" ;
import image5 from "./images/5.jpeg";



const HackAtlas = () => {
    const images = [image1,image5,image2, image3, image4];

    return (
    <section className="RedRadio section" id="RedRadio">
        <h2 className="section__title">HACKATHON 6.0</h2>
        <span className="section__subtitle">First place winner🥇</span>
        <div className="slide-container">
            <Fade>
                {images.map((image, index) => (
                    <div className="divImage1">
                        <img src={image} alt="Slider" />
                    </div>
                ))}
            </Fade>
        </div>
        
        <div className="about__container container grid">
           

            <div className="text__container1">
                <h3 className="title">Description:</h3>
                <br />
                <p className='redRadio__text'>🎉 Our team, Alavengers, clinched the top spot in HACKATHON 6.0, hosted by ATLAS Future Leaders-TBS Chapter at Sesame University on February 24th and 25th. It was an immersive dive into the cybersecurity domain. The hackathon theme centered on integrating AI into the file transfer process to bolster security measures. Our winning solution involved deploying an ML model for proactive malware detection, issuing early alerts for potential threats. Furthermore, we crafted a script to automate port number changes, adding layers of defense to our FTP server against vulnerabilities. This dynamic script adjusts configuration files upon server service launch, ensuring robust security measures.</p>

            </div>
        </div>
    </section>
    );
};

export default HackAtlas;
